@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@smastrom/react-rating/style.css";
@import "react-datepicker/dist/react-datepicker.css";

@font-face {
  font-family: WantedSans;
  font-weight: 900;
  src: url("./assets/fonts/WantedSans-Black.otf");
}
@font-face {
  font-family: WantedSans;
  src: url("./assets/fonts/WantedSans-Bold.otf");
  font-weight: 700;
}
@font-face {
  font-family: WantedSans;
  src: url("./assets/fonts/WantedSans-Medium.otf");
  font-weight: 500;
}
@font-face {
  font-family: WantedSans;
  src: url("./assets/fonts/WantedSans-Regular.otf");
  font-weight: 400;
}

/* Custom class for hiding the scrollbar */
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit-based browsers (Chrome, Safari, etc.) */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Hides scrollbar in IE and Edge */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

@layer base {
  html {
    font-family: "WantedSans";
    // font-feature-settings: 'kern' !important;
    -webkit-font-smoothing: antialiased;
  }
  :root {
    --background: 0 0% 100%;
    --foreground: #000;

    --card: 0 0% 100%;
    --card-foreground: #000;

    --popover: 0 0% 100%;
    --popover-foreground: #000;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: #000;

    --radius: 0.5rem;
  }

  .dark {
    --background: #000;
    --foreground: 210 40% 98%;

    --card: #000;
    --card-foreground: 210 40% 98%;

    --popover: #000;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  @variants responsive {
    .masonry {
      column-gap: 1.5em;
      column-count: 1;
    }
    .masonry-sm {
      column-gap: 1.5em;
      column-count: 2;
    }
    .masonry-md {
      column-gap: 1.5em;
      column-count: 3;
    }
    .masonry-lg {
      column-gap: 1.5em;
      column-count: 4;
    }
    .break-inside {
      break-inside: avoid;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dfdfdf;
}

.loader-main {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e7e7e7;
  padding: 10px;
  border-radius: 18px;
  z-index: 999999;
}
.tags-main {
  input {
    width: 100%;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    margin-top: 2%;
  }
  ::placeholder {
    color: #6b7280;
    font-size: 0.85rem;
  }
  .tag-wrapper {
    background-color: #f3f3f3;
    padding: 0.7% 0 0.7% 2%;
    font-family: WantedSans;
    font-weight: 400;
    margin-right: 2%;
    font-size: 0.9rem;
    button {
      background-color: #f63232;
      padding: 1%;
      margin-left: 1%;
    }
  }
}

#inline-checkout-U1ZbL {
  z-index: 9999999999 !important;
}
// .checkout__outer-container{
//   z-index: 9999999999;
// }
